export const imports = {
  'src/docs/Home.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-home" */ 'src/docs/Home.mdx'
    ),
  'src/docs/useCallback.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-use-callback" */ 'src/docs/useCallback.mdx'
    ),
  'src/docs/useContext.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-use-context" */ 'src/docs/useContext.mdx'
    ),
  'src/docs/useEffect.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-use-effect" */ 'src/docs/useEffect.mdx'
    ),
  'src/docs/useLayoutEffect.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-use-layout-effect" */ 'src/docs/useLayoutEffect.mdx'
    ),
  'src/docs/useMemo.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-use-memo" */ 'src/docs/useMemo.mdx'
    ),
  'src/docs/useReducer.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-use-reducer" */ 'src/docs/useReducer.mdx'
    ),
  'src/docs/useRef.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-use-ref" */ 'src/docs/useRef.mdx'
    ),
  'src/docs/useState.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-use-state" */ 'src/docs/useState.mdx'
    ),
  'src/docs/examples/fetchData.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-docs-examples-fetch-data" */ 'src/docs/examples/fetchData.mdx'
    ),
}
